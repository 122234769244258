import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import { RentalApplicationCredentials } from '~/state/mainAppState';
import { RentalApplicationSteps } from '~/types/RentaApplicationSteps';
import { TransUnionScreeningStatus } from '~/types/RentalApplication';
import { MagicUseCase } from '~/use-cases/magicUseCase';
import { GetRentalApplicationScreeningQuestionsUseCase } from '../screening/getRentalApplicationScreeningQuestionsUseCase';
import { SetCurrentStepUseCase } from './setCurrentStepUseCase';

export class GetRentalApplicationStatusUseCase extends MagicUseCase {
  protected async runLogic(credentials: RentalApplicationCredentials) {
    if (this.getState().user.rentalApplication.settings?.requiresPayment) {
      const isPaid = await rentalApplicationGateway.fetchRentalApplicationPaymentStatus(credentials);
      this.getState().user.rentalApplication.isPaid = isPaid || !this.getState().user.rentalApplication.settings?.requiresPayment;
    }
    if (this.getState().user.rentalApplication.settings?.requiresScreening) {
      const transUnionScreeningStatus = await rentalApplicationGateway.getTransUnionScreeningStatus(credentials);
      this.getState().user.rentalApplication.transUnionScreening = transUnionScreeningStatus;
      if (this.getState().user.rentalApplication.transUnionScreening?.status === TransUnionScreeningStatus.UnVerified) {
        await new GetRentalApplicationScreeningQuestionsUseCase().execute(credentials);
      } else if (this.getState().user.rentalApplication.transUnionScreening?.status === TransUnionScreeningStatus.Verified) {
        await new SetCurrentStepUseCase().execute(RentalApplicationSteps.APPLICATION_SUBMITTED);
        this.navigate(`/rental-applications/${credentials.id}/${credentials.password}/application-submitted`);
      } else if (
        this.getState().user.rentalApplication.transUnionScreening?.status === TransUnionScreeningStatus.ManualVerificationRequired
      ) {
        await new SetCurrentStepUseCase().execute(RentalApplicationSteps.MANUAL_VERIFICATION);
        this.navigate(`/rental-applications/${credentials.id}/${credentials.password}/manual-verification`);
      }
    } else if (this.getState().user.rentalApplication.isPaid || !this.getState().user.rentalApplication.settings?.requiresPayment) {
      await new SetCurrentStepUseCase().execute(RentalApplicationSteps.APPLICATION_SUBMITTED);
      this.navigate(`/rental-applications/${credentials.id}/${credentials.password}/application-submitted`);
    }
  }
}
