import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import { RentalApplicationCredentials } from '~/state/mainAppState';
import { MagicUseCase } from '~/use-cases/magicUseCase';

export class GetRentalApplicationScreeningQuestionsUseCase extends MagicUseCase {
  protected async runLogic(credentials: RentalApplicationCredentials) {
    this.getState().user.rentalApplication.transUnionScreening = await rentalApplicationGateway.getTransUnionScreeningQuestions(
      credentials
    );
  }
}

2;
